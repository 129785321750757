import { Component } from "@angular/core";
import "deep-chat";

@Component({
  selector: "app-chat-widget",
  template: `
    <div class="chat-container">
      <!-- Floating Button -->
      <button
        class="floating-button"
        [class.active]="isOpen"
        (click)="toggleChat()">
<svg *ngIf="!isOpen" class="icon" viewBox="0 0 24 24" width="24" height="24">
          <path fill="currentColor" d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/>
          <path fill="currentColor" d="M7 9h10v2H7zm0-3h10v2H7zm0 6h7v2H7z"/>
        </svg>
        <svg *ngIf="isOpen" class="icon" viewBox="0 0 24 24" width="24" height="24">
          <path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        </svg>      </button>

      <!-- Chat Widget -->
      <div class="chat-widget" *ngIf="isOpen">
        <div class="chat-header">
          <h3 style="color:white;">Chat de soporte</h3>
        </div>

        <!-- aqui esta el componente del chatbot -->
        <deep-chat
textInput='{"placeholder": {"text": "Envia un mensaje"}}'
          directConnection='{

	"openAI": {
		"key": "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",
          "chat": {"max_tokens": 2000, "system_prompt": "Eres un chatbot asistente de IA que se llama alexa. Está capacitado para ayudar a los usuarios brindándoles respuestas exhaustivas y útiles a sus consultas, responde en español, informacion de la empresa para los usuarios: ruc: 10770455745, rubro: libreria que vende libros y utiles escolares y de oficina, ubicacion: jose olaya entre prolongacion centenario, Huancayo - Peru, telefono: 975 310 640, correo: libreriaolayahuancayo@gmail.com, usa muchos emojis"}
	}
}
'
  ></deep-chat>

<!--            <deep-chat -->
<!-- directConnection='{ -->
<!-- 	"cohere": { -->
<!-- 		"key": "Bjn8Wec3I4jExVKB9ETrTpLhQcNKGtzwjSEgHvvv", -->
<!-- 		"chat": { -->
<!-- 			"temperature": 1 -->
<!-- 		} -->
<!-- 	} -->
<!-- }' -->
<!--           textInput='{"placeholder":{"text": "Escribe tu consulta aqui"}}' -->
<!--   ></deep-chat> -->
    </div>
  `,
  styles: [`
    .chat-container {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1000;
    }

    .floating-button {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      box-shadow: 0 2px 10px rgba(0,0,0,0.2);
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
    }

    .floating-button:hover {
      transform: scale(1.1);
    }

    .floating-button.active {
      background-color: #dc3545;
    }

    .chat-widget {
      position: absolute;
      bottom: 80px;
      right: 0;
      width: 300px;
      height: 400px;
      background: white;
      border-radius: 10px;
      box-shadow: 0 5px 15px rgba(0,0,0,0.2);
      display: flex;
      flex-direction: column;
    }

    .chat-header {
      padding: 15px;
      background: #007bff;
      color: white;
      border-radius: 10px 10px 0 0;
    }

    .chat-header h3 {
      margin: 0;
      font-size: 16px;
    }

    .chat-messages {
      flex: 1;
      padding: 15px;
      overflow-y: auto;
    }

    .message {
      margin-bottom: 10px;
    }

    .message-content {
      padding: 8px 12px;
      border-radius: 15px;
      max-width: 80%;
      display: inline-block;
    }

    .message-content.user {
      background: #007bff;
      color: white;
      float: right;
    }

    .message-content.bot {
      background: #f1f1f1;
      float: left;
    }

    .chat-input {
      padding: 15px;
      border-top: 1px solid #eee;
      display: flex;
      gap: 10px;
    }

    .chat-input input {
      flex: 1;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 20px;
      outline: none;
    }

    .chat-input button {
      background: #007bff;
      color: white;
      border: none;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `],
})
export class ChatWidgetComponent {
  isOpen = false;
  newMessage = "";
  messages: Array<{ text: string; type: "user" | "bot" }> = [];

  toggleChat() {
    this.isOpen = !this.isOpen;
  }

  sendMessage() {
    if (this.newMessage.trim()) {
      // Agregar mensaje del usuario
      this.messages.push({
        text: this.newMessage,
        type: "user",
      });

      // Simular respuesta del bot
      setTimeout(() => {
        this.messages.push({
          text: "¡Gracias por tu mensaje! Un agente te responderá pronto.",
          type: "bot",
        });
      }, 1000);

      this.newMessage = "";
    }
  }
}
