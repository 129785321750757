import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  readonly URL_API_ORDER = `${environment.urlAPI}/categories`;

  constructor(private http: HttpClient) {}

    listCategory():any {
    return this.http.get(`${this.URL_API_ORDER}`);
  }

  // createCategory(order: {name:string}) {
  //   return this.http.post(`${this.URL_API_ORDER}/categories`, order);
  // }

  // updateCategory(order, order) {
  //   const body = { order, client };
  //   return this.http.patch(`${this.URL_API_ORDER}/send-email`, body);
  // }
}
