import { Component, OnInit } from "@angular/core";
import { ClientService } from "src/app/services/client.service";
import { TemporaryCartService } from "src/app/services/temporary-cart.service";
import { AuthService } from "src/app/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
  client: any;
  temporaryCart = [];
  subtotalCart = 0;

  constructor(
    public clientService: ClientService,
    private temporaryCartService: TemporaryCartService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.clientService.client$.subscribe((res) => {
      this.client = res;
      this.calculateSubtotal();
    });
    this.temporaryCartService.temporaryCart$.subscribe(
      (res) => {
        this.temporaryCart = res;
        this.calculateSubtotal();
      },
      (err) => {
        console.log("error", err);
      },
    );
  }

  deleteProductFromCart(productCartId) {
    if (this.authService.isLoggedIn()) {
      this.clientService
        .deleteProductFromCart(this.client._id, productCartId)
        .subscribe((res) => {
          this.clientService.refreshClientInfo();
        });
    } else {
      this.temporaryCartService.deleteProduct(productCartId);
    }
  }

  updateQuantity() {
    if (this.authService.isLoggedIn()) {
      console.log(this.client.cart);
      this.clientService.updateCartQuantity(this.client.cart).subscribe(
        (res) => {
          this.clientService.refreshClientInfo();
          this.toastr.success(
            "puede proceder a pagar",
            "Carrito actualizado",
          );
        },
        (err) => {
          console.log(err);
        },
      );
    } else {
      this.temporaryCartService.updateQuantity(this.temporaryCart);
      this.toastr.success(
        "puede proceder a pagar",
        "Carrito actualizado",
      );
    }
  }

  calculateSubtotal() {
    if (this.authService.isLoggedIn()) {
      if (this.client) {
        let sub = 0.0;
        for (const itemCart of this.client.cart) {
          sub = sub + itemCart.quantity * itemCart.product.price;
        }
        this.subtotalCart = sub;
      } else {
        // TODO: aqui habra un problema cuando un cliente añada cosas al carrito cuando no este registrado, solucionar pronto
        this.subtotalCart = 0.0;
      }
    } else {
      let sub = 0.0;
      this.temporaryCart.forEach((e) => {
        sub = sub + e.quantity * e.product.price;
      });
      this.subtotalCart = sub;
    }
  }
}
