import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-venta-efectivo',
  templateUrl: './venta-efectivo.component.html',
  styleUrls: ['./venta-efectivo.component.scss']
})
export class VentaEfectivoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
