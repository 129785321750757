import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ClientService } from "src/app/services/client.service";
import { ToastrService } from "ngx-toastr";
import { TemporaryCartService } from "src/app/services/temporary-cart.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
})
export class ProductCardComponent implements OnInit {
  @Input()
  product: any = {};
  isProductInCart: boolean;
  client;

  constructor(
    private router: Router,
    private temporaryCartService: TemporaryCartService,
    public clientService: ClientService,
    private toastr: ToastrService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.clientService.client$.subscribe(
      (resClient) => {
        this.client = resClient;
        if (this.client) {
          this.verifyProductInCart();
        }
      },
      (err) => {
        console.log(err);
      },
    );
    this.verifyProductInCart();
  }
  goToProduct(product) {
    this.router.navigate(["/product-details", product._id]);
  }

  addToCart() {
    console.log("hola");
    console.log(this.authService.isLoggedIn());
    if (!this.authService.isLoggedIn()) {
      this.temporaryCartService.addProduct(this.product, 1);
      this.verifyProductInCart();
    } else {
      console.log("hola in clientservice");
      this.clientService
        .addProductToCart(this.product._id, 1)
        .subscribe(
          (res) => {
            this.toastr.success("Agregado producto a carrito", "exito");
            console.log("added product to cart");
            this.clientService.refreshClientInfo();
          },
          (err) => {
            this.toastr.error("ocurrio un error", "error");
            console.log(err);
          },
        );
    }
  }

  verifyProductInCart() {
    if (this.authService.isLoggedIn()) {
      const found = this.client.cart.filter(
        (e) => e.product._id === this.product._id,
      );
      console.log(this.client.cart);
      console.log(found);
      if (found.length === 0) {
        this.isProductInCart = false;
      } else {
        this.isProductInCart = true;
      }
    } else {
      console.log("user is not logged in");
      let tempCart = JSON.parse(localStorage.getItem("temporaryCart"));
      if (!tempCart) {
        tempCart = [];
      }
      if (tempCart.length > 0) {
        tempCart.forEach((e) => {
          if (e.product._id === this.product._id) {
            this.isProductInCart = true;
          } else {
            // TODO: verificar este else por que si ponemos la ropiedad como false no tendriamos que hacer este
            // else, pero ver si tiene alguna repercusion en especial en el boton añadir al carrito
            this.isProductInCart = false;
          }
        });
      } else {
        this.isProductInCart = false;
      }
    }
  }
}
