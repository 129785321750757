import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { FormGroup, FormBuilder, Validators ,ReactiveFormsModule} from '@angular/forms';
import { OrderService } from 'src/app/services/order.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UbigeoService } from 'src/app/services/ubigeo.service';
import { ToastrService } from 'ngx-toastr';
import { CulqiService } from 'src/app/services/culqi.service';
import { Subscription } from 'rxjs';


declare let Culqi: any;

@Component({
  selector: 'app-purchase-data',
  templateUrl: './purchase-data.component.html',
  styleUrls: ['./purchase-data.component.scss']
})
export class PurchaseDataComponent implements OnInit, OnDestroy {
  deliveryPrice=7
  client: any;
  clientSubscription: Subscription;
  total: number = 0; //el precio total que acumula el carrito

  // forms
  paymentMethodsForm: FormGroup;
  newAddressForm: FormGroup;
  shippingAddressForm: FormGroup;

  newAddressSelectedFlag = false; //si esta en true despliega el formulario de nueva direccion
  defaultAddress: any; // el objeto address que tenga la propiedad isDefault en true o si no hay addresses entonces tendra un objeto con una propiedad _id: 'newAddress

  //ubigeo
  departments = [];
  provinces = [];
  districts = [];

  purchaseState = 'Pago pendiente';

  constructor(
    private clientService: ClientService,
    private orderService: OrderService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    public ubigeoService: UbigeoService,
    private culqi: CulqiService,
    private toastr: ToastrService // https://blog.jscrambler.com/how-to-create-angular-toastr-notifications/ leer el articulo para implementar un wrapper
  ) {}

  ngOnInit() {
    console.log('nginit de purchase component');
    this.departments = this.ubigeoService.departments;
    this.culqi.initCulqi();
    if (this.authService.isLoggedIn() === true) {
      console.log('hay cliente');
      // esto lo saque de aqui https://desarrolloweb.com/articulos/eliminar-suscripcion-observable-angular.html
      // solo que omiti esta instruccion   this.clientes$ = this.clientesService.getClientes$();
      this.clientSubscription = this.clientService.client$.subscribe(
        res => {
          console.log(res);
          this.client = res;
          if (this.client) {
            this.defaultAddress = this.clientService.getDefaultAddress(
              this.client.addresses
            );
            if (this.defaultAddress._id == 'newAddress') {
              this.newAddressSelectedFlag = true;
            } else {
              this.newAddressSelectedFlag = false;
            }

            this.calculateTotal();
            if (this.client.cart.length == 0) {
              console.log('0 items en el carrito del cliente');
              this.router.navigate(['/home']);
            }
            this.initForms();
          }
        },

        err => {
          console.log(err);
        }
      );
    } else {
      this.router.navigate(['/login-register'], {
        queryParams: { toCheckout: true }
      });
    }

  }

  ngOnDestroy(): void {
    //nos desuscribimos del cliente por que daba un error como la subscripcion se quedaba en memoria al refrescar la info del cliente  como su carrito ya se habia vaciado y quedado en 0 entonces la instruccion dentro de la subscripcion indicaba que debia ir hacia home haciendo que no se quedara en after purchase
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
  }

  calculateTotal() {
    let cantidad: number = 0;
    for (const i in this.client.cart) {
      cantidad +=
        this.client.cart[i].product.price * this.client.cart[i].quantity;
    }
    this.total = cantidad;
  }

  selectChanged(value) {
    if (value == 'newAddress') {
      this.newAddressSelectedFlag = true;
    } else {
      this.newAddressSelectedFlag = false;
    }
  }

  // Purchase
  makePurchase(
    newAddressForm: FormGroup,
    paymentMethodsForm: FormGroup,
    shippingAddressForm: FormGroup,
    btnMakePurchase: HTMLButtonElement,
    proccesingPurchaseSpinner: HTMLElement
  ) {
    const { shippingAddressSelected } = shippingAddressForm.value; // contiene un id de una direccion o 'newAddress'

    if (paymentMethodsForm.value.paymentMethod == 'Transferencia bancaria') {
      btnMakePurchase.classList.add('d-none');
      proccesingPurchaseSpinner.classList.remove('d-none');
      proccesingPurchaseSpinner.classList.add('d-flex');

      if (shippingAddressSelected == 'newAddress') {
        //obtenemos el objeto de cada dato de ubigeo basandonos en el id que dimos
        const { department, province, district } = this.ubigeoService.getUbigeo(
          newAddressForm.value.department,
          newAddressForm.value.province,
          newAddressForm.value.district
        );

        //agregamos los objetos encima de los id de los mismos
        newAddressForm.value.department = department;
        newAddressForm.value.province = province;
        newAddressForm.value.district = district;

        this.clientService
          .createAddress(newAddressForm.value, this.client.addresses.length) // agregamos la direccion al usuario
          .subscribe(
            (res: any) => {
              let newOrder = {
                // creamos el nuevo objeto orden
                destinationAddress: res.result.addresses[0],
                paymentMethod: paymentMethodsForm.value.paymentMethod,
                products: this.client.cart,
                totalPrice: this.total + this.deliveryPrice,
                state: this.purchaseState
              };
              this.orderService.createWireTransfer(newOrder).subscribe(
                // creamos la orden
                (order: any) => {
                  this.clientService.addOrder(order._id).subscribe(
                    // si la orden se agrega correctamente extraemos el id que devuelve y lo agregamos a las ordenes del cliente
                    res => {
                      this.orderService.sendEmail(order, this.client).subscribe(
                        res => {
                          this.toastr.success(
                            'verifica el estado de tu orden en tu panel de control',
                            'Orden exitosa'
                          );
                          this.router.navigate(['/after-purchase'], {
                            queryParams: { paymentMethod: 'wireTransfer' }
                          });
                        },
                        error => {
                          console.log(error);
                          btnMakePurchase.style.display = 'block';
                          proccesingPurchaseSpinner.style.display = 'none';
                        }
                      );
                    },

                    err => {
                      console.log(err);
                    }
                  );
                },
                err => {
                  console.log(err);
                }
              );
            },
            err => {
              console.log(err);
            }
          );
      } else {
        // si se selecciona una direcion existente se ejecuta este codigo

        let destinationAddress;
        this.client.addresses.forEach(e => {
          // obtenemos la direccion seleccionada en forma de todo el objeto
          if (e._id == shippingAddressSelected) {
            destinationAddress = e;
          }
        });
        //  creamos el nuevo objeto order
        let newOrder = {
          destinationAddress,
          paymentMethod: paymentMethodsForm.value.paymentMethod,
          products: this.client.cart,
          totalPrice: this.total+this.deliveryPrice,
          state: this.purchaseState
        };

        this.orderService.createWireTransfer(newOrder).subscribe(
          //creamos la orden
          (order: any) => {
            this.clientService.addOrder(order._id).subscribe(
              //añadimos el id de la orden creada a las ordenes del cliente
              res => {
                this.orderService.sendEmail(order, this.client).subscribe(
                  res => {
                    this.toastr.success(
                      'verifica el estado de tu orden en tu panel de control',
                      'Orden exitosa'
                    );
                    console.log('exitoso envio de mails');
                    this.router.navigate(['/after-purchase'], {
                      queryParams: { paymentMethod: 'wireTransfer' }
                    });
                  },
                  error => {
                    console.log(error);
                    btnMakePurchase.classList.remove('d-none');
                    btnMakePurchase.classList.remove('d-flex');
                    proccesingPurchaseSpinner.classList.remove('d-flex');
                    proccesingPurchaseSpinner.classList.add('d-none');
                  }
                );
              },

              err => {
                console.log(err);
              }
            );
          },
          err => {
            console.log(err);
          }
        );
      }
    } else if (
      paymentMethodsForm.value.paymentMethod == 'Tarjeta de credito o debito'
    ) {
      if (shippingAddressSelected == 'newAddress') {
        //obtenemos el objeto de cada dato de ubigeo basandonos en el id que dimos
        const { department, province, district } = this.ubigeoService.getUbigeo(
          newAddressForm.value.department,
          newAddressForm.value.province,
          newAddressForm.value.district
        );

        //agregamos los objetos encima de los id de los mismos
        newAddressForm.value.department = department;
        newAddressForm.value.province = province;
        newAddressForm.value.district = district;

        this.clientService
          .createAddress(newAddressForm.value, this.client.addresses.length) // agregamos la direccion al usuario
          .subscribe(
            (res: any) => {
              let newOrder = {
                // creamos el nuevo objeto orden
                destinationAddress: res.result.addresses[0],
                paymentMethod: paymentMethodsForm.value.paymentMethod,
                products: this.client.cart,
                totalPrice: this.total + this.deliveryPrice,
                state: this.purchaseState
              };
              try {
                this.culqi.payorder(
                  this.total,
                  newOrder,
                  this.client,
                  btnMakePurchase,
                  proccesingPurchaseSpinner
                );
              } catch (error) {
                console.log(error);
                this.toastr.error(
                  error,
                  'Hubo un error al momento de procesar la compra'
                );
                btnMakePurchase.style.display = 'block';
                proccesingPurchaseSpinner.style.display = 'none';
              }
            },
            err => {
              console.log('error creando la direccion');
              console.log(err);
              this.clientService.refreshClientInfo();
              btnMakePurchase.classList.remove('d-none');
              btnMakePurchase.classList.remove('d-flex');
              proccesingPurchaseSpinner.classList.remove('d-flex');
              proccesingPurchaseSpinner.classList.add('d-none');
            }
          );
      } else {
        // si se selecciona una direcion existente se ejecuta este codigo
        let destinationAddress;

        this.client.addresses.forEach(e => {
          // obtenemos la direccion seleccionada en forma de todo el objeto
          if (e._id == shippingAddressSelected) {
            destinationAddress = e;
          }
        });
        //  creamos el nuevo objeto order
        let newOrder = {
          destinationAddress,
          paymentMethod: paymentMethodsForm.value.paymentMethod,
          products: this.client.cart,
          totalPrice: this.total+this.deliveryPrice,
          state: this.purchaseState
        };

        try {
          this.culqi.payorder(
            this.total,
            newOrder,
            this.client,
            btnMakePurchase,
            proccesingPurchaseSpinner
          );
        } catch (error) {
          console.log(error);
          this.toastr.error(
            error,
            'Hubo un error al momento de procesar la compra, por favor intenta mas tarde'
          );
          btnMakePurchase.classList.remove('d-none');
          btnMakePurchase.classList.remove('d-flex');
          proccesingPurchaseSpinner.classList.remove('d-flex');
          proccesingPurchaseSpinner.classList.add('d-none');
        }
      }
    }
  }

  //ubigeo
  //ubigeo
  changeDepartment(idDepartment) {
    this.districts = [];
    this.provinces = [];
    this.provinces = this.ubigeoService.getProvincesById(idDepartment);
    this.newAddressForm.value.province = '';
    this.newAddressForm.value.district = '';
    this.newAddressForm.reset(this.newAddressForm.value);
  }
  changeProvince(idProvince) {
    this.districts = [];
    this.districts = this.ubigeoService.getDistrictsById(idProvince);
    this.newAddressForm.value.district = '';
    this.newAddressForm.reset(this.newAddressForm.value);
  }

  // forms
  initForms() {
    this.newAddressForm = this.fb.group({
      firstName: [this.client.firstName, [Validators.required,Validators.minLength(2), Validators.pattern(/^[a-zA-Z\s]+$/)]],
      lastName: [this.client.lastName, [Validators.required,Validators.minLength(2), Validators.pattern(/^[a-zA-Z\s]+$/)]],
      DNI: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^\d{8}$/)]],
      cellPhone: ['', [Validators.required, Validators.minLength(9),Validators.pattern(/^9\d{8}$/)]],
      district: ['', Validators.required],
      province: ['', [Validators.required]],
      department: ['', [Validators.required]],
      shippingAddress: ['', [Validators.required,Validators.minLength(5)]]
    });

    this.paymentMethodsForm = this.fb.group({
      paymentMethod: ['Transferencia bancaria']
    });
    this.shippingAddressForm = this.fb.group({
      shippingAddressSelected: [this.defaultAddress._id]
    });
  }
}
