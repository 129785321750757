import { Component, OnInit } from '@angular/core';
import { FakeDbService } from 'src/app/services/fake-db.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  // styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(public fakeDB: FakeDbService) { }

  ngOnInit() {
  }

}
