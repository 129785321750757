import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-venta-visa',
  templateUrl: './venta-visa.component.html',
  styleUrls: ['./venta-visa.component.scss']
})
export class VentaVisaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
