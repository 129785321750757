import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProductService } from "src/app/services/product.service";
// import { Product } from 'src/app/models/product';
import { Router } from "@angular/router";
import { ClientService } from "src/app/services/client.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  client: any;
  p: number = 1;
  label: any = {
    previousLabel: "Anterior",
    nextLabel: "Siguiente",
  };
  config = {
    id: "custom",
    itemsPerPage: 20,
    currentPage: 1,
  };

  //slider configuration
  public innerWidth: any;
  // aqui estan los sliders con link
  products = [
    {
      title: "RED Widgets",
      url:
        "https://libreria.fixa.digital/product-details/672f6f37fcba0a54c3f70ab0",
      regularPrice: "100.00",
      salePrice: "100.00",
      image: `assets/images/slider/banner.jpg`,
    },
    {
      title: "YELLOW Widgets",
      url: "#",
      regularPrice: "100.00",
      salePrice: "100.00",
      image: `assets/images/slider/banner1.jpg`,
    },
    {
      title: "Black Widgets",
      url: "#",
      regularPrice: "100.00",
      salePrice: "100.00",
      image: `assets/images/slider/banner2.jpg`,
    },
  ];
  options1 = {
    animation: {
      animationClass: "transition",
      animationTime: 200,
    },
    swipe: {
      swipeable: true,
      swipeVelocity: 1,
    },
    drag: {
      draggable: true,
      dragMany: true,
    },
    infinite: true,
    autoplay: {
      enabled: true,
      direction: "right",
      delay: 5000,
      stopOnHover: true,
      speed: 6000,
    },
    breakpoints: [
      {
        width: 768,
        number: 1,
      },
      {
        width: 991,
        number: 1,
      },
      {
        width: 9999,
        number: 1,
      },
    ],
  };
  //end slider configuration

  constructor(
    public productService: ProductService,
    public route: Router,
    public clientService: ClientService,
  ) {}

  ngOnInit() {
    console.log("nginit de home component");
    this.getProducts();
    this.clientService.client$.subscribe(
      (res) => {
        this.client = res;
      },
      (err) => {
        console.log(err);
      },
    );
  }
  onPageChange(event) {
    console.log(event);
    this.config.currentPage = event;
  }
  goToProduct(product) {
    this.route.navigate(["/product-details", product._id]);
  }

  getProducts() {
    this.productService.getAll().subscribe((res) => {
      this.productService.products = res as any[];
    });
  }

  ngon;
  ngOnDestroy(): void {
    console.log("home destroyed");
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
